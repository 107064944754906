<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card outlined class="primary--border">
                    <v-card-title class="title">
                        Online Registration
                        <v-spacer></v-spacer>
                        <!--<v-btn outlined @click="form.dialog = true" ma-0 small>New Notice</v-btn>-->
                    </v-card-title>

                    <v-data-table :headers="headers" :items="form.items.data"
                                  :server-items-length="form.items.meta.total"
                                  :options.sync="pagination"
                                  :footer-props="footerProps"
                    >
                        <template  v-slot:item="{index, item}">
                            <tr>
                                <td>{{ index + 1 }}</td>
                                <td class="text-xs-left">
                                    {{ item.student_name }} <br>
                                    {{ item.dob }}
                                </td>
<!--                                <td class="text-xs-left">{{ item.dob }}</td>-->
<!--                                <td class="text-xs-left">{{ item.gender }}</td>-->
                                <td class="text-xs-left">{{ item.current_class }}/
                                <p>{{item.applying_class}}</p></td>
                                <td class="text-xs-left">
                                    {{ item.guardian_name }}
                                    <p>{{item.mobile}} {{item.landline ? '(' + item.landline + ')' : ''}}</p>
                                </td>
                                <td class="text-xs-left">{{ item.current_school }}</td>

                                <td class="text-xs-left">
                                    {{ item.perm_address }}/
                                    <p>{{item.temp_address}}</p>
                                </td>
                                <td class="text-xs-left">
                                    <span if="item.see_details">
                                        <p style="margin: 0"><strong>Symbol No :</strong> {{item.see_details.symbol_no}}</p>
                                        <p style="margin: 0"><strong>Medium : </strong>{{item.see_details.medium}}</p>
                                        <p style="margin: 0"><strong>Grade : </strong>{{item.see_details.grade}}</p>
                                    </span>
                                </td>
                                <td class="text-xs-left">
                                    {{ item.created_at }}
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'

    const NepaliDate = require('nepali-date');
    const nd = new NepaliDate();

    import Mixins from '@/library/Mixins';

    export default {
        mixins: [Mixins],
        data: () => ({
            form: new Form({
            }, '/api/admit-enquiry-form'),
            search: null,
            rowsPerPageItems: [10, 20, 50, 75, 100],
            pagination: {
                rowsPerPage: 20,
            },
            headers: [
                {text: 'SN', align: 'center', value: 'id', width: 50},
                {text: 'Student Name/DOB', align: 'left', value: 'student_name'},
                // {text: 'DOB', align: 'left', value: 'dob'},
                // {text: 'Gender', align: 'left', value: 'gender'},
                {text: 'Current Class/Admission Class', align: 'left', value: 'role'},
                {text: 'Guardian Name', align: 'left', sortable: false, width: 75},
                {text: 'Current School', align: 'left', value: 'current_school', sortable: false},
                {text: 'Per/Tem Address', align: 'left', value: 'temp_address'},
                {text: 'SEE Detail', align: 'left', value: 'see_details'},
                {text: 'Request Date', align: 'left', value: 'created_at'},

                // {text: 'Action', align: 'right', sortable: false, width: 75}
            ],
        }),

        computed: {
            ...mapState(['batch']),
        },
        watch: {
            'pagination': function () {
                this.get();
                // this.fetchRoles();
            }
        },
        mounted() {
            this.get();
        },

        methods: {
            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&');
            },


            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({data}) => {
                    this.pagination.totalItems = data.meta.total
                })
            },


        }
    }
</script>
<style lang="scss">
    .notice_form {
        .v-chip, .v-chip .v-chip__content {
            border-radius: 2px;
            color: white;
        }
    }
</style>